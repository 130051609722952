@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-teamcard{
    margin-bottom: 16px;
    @include cardlayout;
    padding: 24px 24px 16px;
    a{
        display: block;
    }
}
.rt-teamhead{
    margin-bottom: 16px;

    .rt-roundimage{
        width: 56px;
        height: 56px;
        line-height: 56px;
    }

    .rt-themecontent{
        h4{
            font-size: 16px;
            margin-bottom: 5px;
        }
        span{
            display: block;
            font-size: 14px;
            color: $DarkGray;
            margin-bottom: 3px;
        }
        em{
            display: block;
            font-size: 12px;
            color: $DarkGray;
            font-style: normal;
            @include roboto-regular;
        }
    } 
}
.rt-teamcontent{
    .rt-bannerimg{
        height: 250px;
        margin-bottom: 16px;
    }
    .rt-bannercontent{
        h2{
            font-size: 1.5rem;
            i{
                margin-right: 12px;
            }
        }
    }
    .rt-counterbox{
        margin: 0;
        padding: 0;
        .rt-count{
            h3{
                font-size: 1.875rem;
            }
        }
    }
}
/* =============================================
            Responsive
============================================= */

@media (max-width: 575px) {
    .rt-teamcontent .rt-bannerimg{
        height: 150px;
    }    
}
