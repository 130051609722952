@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-challenges-details{
    .rt-bannercontent-holder{
        .rt-time{
            @include norwester;
            font-size: 1.125rem;
            margin-bottom: 47px;
        }
        .rt-description{
            margin: 0;
        }
    } 
    .rt-coursecontentarea{
        .rt-banner{
            margin-bottom: 77px;
        }
    }
}
.rt-challenges-detailscontent{
    @include cardlayout;
    padding: 20px 20px 30px;
    .rt-description{
        p{
            margin: 0;
            color: $nero;
            font-size: 16px;
            @include roboto-regular;
        }
    }
    .rt-badgebox{
        display: flex;
        text-align: center;
        margin: -70px 0 20px;
        flex-direction: column;
        .rt-roundimage{
            width: 90px;
            height: 90px;
            line-height: 90px;
            margin: 0 auto 10px;
        }
        h3{
            color: $nero;
            margin-bottom: 5px;
            font-size: 1.125rem;
            @include roboto-regular;
        }
        span{
            display: block;
            font-size: 14px;
            color: $DarkGray;
            @include roboto-regular;
        }
    }
}
.rt-progressbarbox{
    margin-bottom: 48px;

    p{
        color: $nero;
        font-size: 16px;
        margin-bottom: 16px;
        @include roboto-regular;
    }

    .progress{
        height: 10px;
        @include bd-radius(16px);
        background: $verylightgreytwo;
    }
    .progress-bar{
        background: $FormalGarden;
    }
}
.rt-memberslist{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 26px;
    li{
        padding: 0 8px 10px;
        @include transition;
        list-style-type: none;
        &:first-child{
            padding-left: 0;
        }
        .rt-themecardbox{
            overflow: hidden;
            position: relative;
            @include transition;
            .rt-themecontent{
                right: -180px;
                position: absolute;
                @include transition;
            }
        }
        &:hover{
            .rt-themecardbox{
                .rt-themecontent{
                    position: static;
                }
            }
        }
        .rt-roundimage{
            width: 56px;
            height: 56px;
            line-height: 56px;
        }
        .rt-themecontent{
            h4{
                font-size: 16px;
                margin-bottom: 5px;
                @include norwester;
                em{
                    font-size: 10px;
                    color: $RainyGrey;
                    font-style: normal;
                }
            }
            span{
                color: $nero;
                display: block;
                font-size: 14px;
                @include norwester;
            }
        }
    }
}
.rt-datebox{
    color: $grey;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    i{
        width: 40px;
        height: 40px;
        color: $grey;
        line-height: 40px;
        margin-right: 12px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        background: $Gainsboro;
        @include bd-radius(50%);
    }
}