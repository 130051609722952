@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-videocontentarea{
    .rt-banner{
        overflow: hidden;
        margin-bottom: 30px;
        background-size: cover;
        @include bd-radius(20px);
    }
    .rt-videobox{
        width: 100%;
        height: 450px;
        @include bd-radius(20px);
    }
    .rt-videoplay{
        width: 100%;
        height: 100%;
        @include bd-radius(20px);
    }

    .rt-bannercontent{
        position: static;
        padding: 24px 20px;
        flex-direction: column;
        align-items: flex-start;
        background: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, #1B1B1B 100%);

        h3{
            color: $white;
            font-size: 1.5rem;
            @include norwester;
            margin-bottom: 10px;
        }
        p{
            margin: 0;
            color: $white;
            font-size: 16px;
            @include roboto-regular;
        }
    }
}


/* =============================================
            Responsive
============================================= */

@media (max-width: 991px) {
    
}
@media (max-width: 575px) {
    .rt-videocard .rt-themecardbox{
        flex-direction: column;
    }
    .rt-videoimg{
        margin: 0 0 15px;
    }
}
@media (max-width: 479px){
    
}

