@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?g6fluk');
  src:  url('../fonts/icomoon.eot?g6fluk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?g6fluk') format('truetype'),
    url('../fonts/icomoon.woff?g6fluk') format('woff'),
    url('../fonts/icomoon.svg?g6fluk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e90f";
}
.icon-arrow-left:before {
  content: "\e910";
}
.icon-calander:before {
  content: "\e911";
}
.icon-clock:before {
  content: "\e912";
}
.icon-delet:before {
  content: "\e913";
}
.icon-edit:before {
  content: "\e914";
}
.icon-gol:before {
  content: "\e915";
}
.icon-link:before {
  content: "\e916";
}
.icon-logout:before {
  content: "\e917";
}
.icon-moon:before {
  content: "\e918";
}
.icon-pencil:before {
  content: "\e919";
}
.icon-pluse:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-setting:before {
  content: "\e91c";
}
.icon-sun:before {
  content: "\e91d";
}
.icon-tick:before {
  content: "\e91e";
}
.icon-video:before {
  content: "\e91f";
}
.icon-dashboard:before {
  content: "\e902";
}
.icon-club:before {
  content: "\e900";
}
.icon-cross:before {
  content: "\e901";
}
.icon-dollar:before {
  content: "\e903";
}
.icon-down-arrow:before {
  content: "\e904";
}
.icon-error:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-football:before {
  content: "\e907";
}
.icon-leaderboard:before {
  content: "\e908";
}
.icon-left-arrow:before {
  content: "\e909";
}
.icon-progress:before {
  content: "\e90a";
}
.icon-right-arrow:before {
  content: "\e90b";
}
.icon-teammates:before {
  content: "\e90c";
}
.icon-tropy:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}
