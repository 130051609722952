@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-coursecontentarea {
    .rt-banner{
        overflow: hidden;
        margin-bottom: 30px;
        @include bd-radius(16px);
        background: url(../../assets/images/banner-img2.jpg) no-repeat;
        background-size: cover;
    }

    .rt-bannercontent{
        position: static;
        padding: 30px 20px;
        align-items: start;
        background: rgba(27,27,27,0.5);
        justify-content: space-between;
    }
}

.rt-bannercontent-holder{
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);

    .rt-time{
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
        color: $electriclime;
        @include roboto-regular;

        i{
            margin-right: 8px;
        }
    }

    h2{
        color: $white;
        font-size: 1.75rem;
        margin-bottom: 10px;
    }

    .rt-description{
        margin: 0 0 30px;
        p{
            margin: 0;
            color: $white;
            font-size: 16px;
            @include roboto-regular;
        }
    }

    .rt-courseinfolist{
        display: flex;
        list-style: none;
        flex-direction: column;
        
        li{
            color: $white;
            font-size: 16px;
            padding: 0 0 12px;
            @include norwester;
            list-style-type: none;

            i{
                margin-right: 16px;
            }

            &:last-child{
                padding: 0;
            }

        }
    }
}

.rt-rightbox{
    display: flex;
    flex-direction: column;

    h3{
        color: $white;
        margin: 0 0 12px;
        font-size: 1.5rem;
    }
    .rt-statsbtn{
        padding: 0 21px;
        font-size: 16px;
        line-height: 40px;
        @include bd-radius(7px);
    }
}

/* =============================================
            Goals Modal Styling
============================================= */
.rt-goalsmodal{
    .modal-dialog{
        max-width: 456px;
    }
    .modal-content{
        overflow: hidden;
        background: url(../../assets/images/goalsmodal-bg.jpg) no-repeat;
        background-size: cover;
    }
    .modal-body{
        background: rgba(0, 0, 0, 0.7);
    }
}
.rt-goalsmodalbox{
    padding: 58px 30px 40px;
}
.rt-goalscontent{
    text-align: center;
    .rt-modaltitle{
        margin-bottom: 24px;

        h3{
            color: $white;
            text-align: left;
            line-height: 1.3;
        }
    }
    .rt-customcheckbox{
        .rt-checkmark{
            background: $white;
            border-color: $white;
        }
    }
    .rt-customcheckbox-area{
        text-align: left;
        margin-bottom: 40px;
        span{
            color: $white;
            font-size: 14px;
            @include norwester;
        }
    }
    h4{
        color: $white;
        @include norwester;
        font-size: 1.25rem;
        margin-bottom: 40px;
    }
    .rt-subitstat{
        margin-top: 12px;
        line-height: 50px;
    }
}
.rt-goalinfolist{
    display: flex;
    list-style: none;
    text-align: center;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;

    li{
        list-style-type: none;

        label{
            color: $white;
            font-size: 18px;
            @include norwester;
            margin-bottom: 12px;
        }

        .form-control{
            margin: 0;
            height: 70px;
            width: 120px;
            font-size: 20px;
            padding: 10px 20px;
            text-align: center;
            background: $white;
            @include norwester;
            @include bd-radius(10px);

        }
    }
}
/* =============================================
            Share Goals Modal Styling
============================================= */
.rt-sharegoalsmodal{
    .modal-content{
        background: url(../../assets/images/sharegoalsmodal-bg.jpg) no-repeat;
    }

    .rt-modaltitle{
        margin-bottom: 48px;

        h3{
            text-align: center;
        }
    }

    h4{
        margin-bottom: 56px;

        span{
            color: $electriclime;
        }
    }
    .rt-description{
        display: flex;
        margin-bottom: 48px;

        p{
            margin: 0;
            color: $white;
            font-size: 16px;
            @include norwester;
        }
    }
    .rt-formtheme{

        fieldset{

            .rt-shareinput{
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;
                @include bd-radius(10px);
                
                .form-control{
                    margin: 0;
                    height: 50px;
                    background: $white;
                    padding: 15px 60px 15px 30px;   
                }

                .rt-sharebtn{
                    top: 0;
                    right: 0;
                    width: 50px;
                    color: $nero;
                    font-size: 20px;
                    line-height: 50px;
                    text-align: center;
                    position: absolute;
                    background: $Gainsboro;
                }
            }
        }
    } 
}
.rt-goalstatbox{
    width: 120px;
    height: 70px;
    background: $white;
    padding: 10px 15px;
    @include bd-radius(10px);
    border: 2px solid $electriclime;

    em{
        color: $nero;
        display: block;
        font-size: 18px;
        @include norwester;
        font-style: normal;
        margin-bottom: 11px;
    }

    span{
        color: $nero;
        display: block;
        font-size: 14px;
        @include norwester;
    }
}
/* =============================================
            Responsive
============================================= */

@media (max-width: 991px) {
    .rt-coursecontentarea .rt-bannercontent{
        flex-direction: column;
    }
    .rt-bannercontent-holder{
        margin-bottom: 30px;
        width: 100%;
    }
}
@media (max-width: 575px) {
    .rt-videocard .rt-themecardbox{
        flex-direction: column;
    }
    .rt-videoimg{
        margin: 0 0 15px;
    }
}
@media (max-width: 479px){
    .rt-goalsmodalbox {
        padding: 58px 15px 40px;
    }
    .rt-goalinfolist{
        flex-direction: column;
        li{
            margin-bottom: 20px;
            &:last-child{
                margin: 0;
            }
        }
    }
}

