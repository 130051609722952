@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-categorycontentarea{
    
    .rt-banner{
        margin-bottom: 20px;
    }

}

.rt-bannerimg{
    margin: 0;
    width: 100%;
    height: 160px;
    overflow: hidden;
    position: relative;
    @include bd-radius(16px);

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
}

.rt-bannercontent{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
    position: absolute;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, rgba(27, 27, 27, 0.604167) 50.98%, #1B1B1B 100%);
    
    h2{
        color: $white;
        font-size: 1.875rem;
    }
}

.rt-courses{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 16px;
}
/* =============================================
            Responsive
============================================= */

@media (max-width: 1660px) {
    .rt-courses {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}