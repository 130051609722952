@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-memberprofile{
    .rt-counterbox{
        margin-bottom: 40px;
        .rt-followingmodal-btn{
            cursor: default;
        }
    }
    .rt-profileactions{
        .rt-border-btn{
            height: 70px;
            span{
                vertical-align: top;
            }
        }
    } 
}
.rt-nodatafound{
    height: 266px;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    @include cardlayout;
    justify-content: center;

    p{
        margin: 0;
        color: $PinkSwan;
        font-size: 1.625rem;
    }
}
.rt-profileactions{
    display: flex;
}

@media (max-width: 767px) {
    .rt-memberprofile{
        .rt-profileactions{
            .rt-black-btn{
                min-width: 226px;
                span{
                    display: block;
                }
            }
        }
    }
}
@media (max-width: 575px) {
    .rt-memberprofile{
        .rt-profileactions{
            .rt-black-btn{
                height: 60px;
                min-width: 150px;
                line-height: 60px;
            }
            .rt-border-btn{
                height: 60px;
                padding: 0 30px;
                line-height: 60px;
            }
        }
    }
}