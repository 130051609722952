$white: #fff;
$black: #000;
$nero: #1b1b1b;
$nerolight: rgba(27,27,27,0.5);
$nobel: #9e9e9e;
$whisper: #eeeeee;
$mariner: #3b5999;
$zambezi:#585858;
$inchworm:#b7e00a;
$nightrider: #333;
$suvagrey:#929292;
$darkgreen:#000a06;
$blackbean:#040504;
$cinnabar: #ea3333;
$whispertwo:#e4e4e4;
$suvagreytwo:#8e8b8b;
$inchwormtwo:#c3eb1d;
$whisperthree:#e6e6e6;
$whisperfour:#e9e9e9;
$whisperfive:#e5e5e5;
$electriclime:#ceff00;
$suvagreythree:#8d8d8d;
$verylightgrey:#c8c8c8;
$verylightgreytwo:#c9c9c9;
$silver: #bbbbbb;
$grey: #767676;
$Gainsboro: #d9d9d9;
$DarkGray: #ababab;
$Yellow: #fff500;
$OliveDrab: #748D0D;
$BlackBean: #1D221D;
$PinkSwan: #b2b2b2;
$DarkGraylght: rgba(27,27,27,0.1);
$Mischka: #B3B4B6;
$NightRider: #323232;
$Wewak: #FE8081;
$Salomie: #FFD27F;
$FormalGarden: #3c9d51;
$RainyGrey: #a4a4a4
