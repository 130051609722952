@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-header{
    top: 0;
    right: 0;
    z-index: 11;
    display: flex;
    position: fixed;
    padding: 22px 40px;
    align-items: center;
    width: calc(100% - 344px);
    background: $electriclime;
    @include transition($what: all, $time: 500ms, $how: ease-in-out);

    .rt-togglemenubtn{
        display: none;
        margin-right: 10px;
        font-size: 1.875rem;
        background: transparent;

        svg{
            vertical-align: top;
        }
    }

    h1{
        margin: 0;
        color: $nero;
        font-size: 2.5rem;
        @include norwester;
        text-transform: uppercase;
    }
}
.rt-addnav{
    display: flex;
    margin-left: auto;
    align-items: center;

    .rt-changemoodbtn{
        width: 60px;
        height: 60px;
        line-height: 60px;
        background: $nero;
        margin: 0 24px 0 0;

        i{
            color: $Yellow;
            font-size: 26px;
        }
    }

    .rt-themecardbox{
        h4{
            color: $nero;
            margin: 0 0 0;
            @include norwester;
            font-size: 1.25rem;
            text-transform: uppercase;
        }
        span{
            display: block;
            font-size: 14px;
            color: $OliveDrab;
            @include norwester;
            text-transform: uppercase;
        }
    }
}
@media (max-width: 1660px) {
    .rt-header .rt-togglemenubtn{
        display: block;
    }
    .rt-header{
        width: calc(100% - 100px);
    }
    .rt-closesidebar{
        .rt-header{
            width: calc(100% - 344px);
        }
    }
}
@media (max-width: 1440px) {}
@media (max-width: 1199px) {
    .rt-header{
        padding: 22px 30px;
    }
}
@media (max-width: 767px) {
    .rt-addnav .rt-changemoodbtn{
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 15px;
        i{
            font-size: 16px;
        }
    }
    .rt-topbarprofile{
        .rt-themecardbox{
            .rt-roundimage{
                width: 50px;
                height: 50px;
                margin: 0;
                line-height: 50px;
            }
            .rt-themecontent{
                display: none;
            }
        } 
    }
}
@media (max-width: 640px) {
    .rt-header{
        width: 100%;
    }
    .rt-closesidebar .rt-header{
        width: 100%;
    }
}
@media (max-width: 575px) {
    .rt-header h1{
        font-size: 2rem;
    }
}
@media (max-width: 479px) {
    .rt-header {
        padding: 22px 20px;
    }
    .rt-header h1{
        font-size: 1.875rem;
    }
    .rt-header .rt-togglemenubtn{
        font-size: 1.5rem;
    }
    
}
@media (max-width: 359px) {}