@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-selectvideocard{
    .rt-themecardbox{
        padding: 10px 0;
        align-items: flex-start;
    }
    .rt-videoimg{
        width: 90px;
        height: 90px;
        overflow: unset;
        @include transition;
        
        img{
            @include bd-radius(16px);
        }
        .rt-checkmark{
            width: 100%;
            height: 100%;
            position: absolute;
            @include bd-radius(16px);
            border: 2px solid $whisperfive;
            &::after{
                top: 50%;
                left: 50%;
                padding: 3px;
                font-size: 12px;
                color: $electriclime;
                @include bd-radius(50%);
                border: 2px solid $electriclime;
                transform: translate(-50%, -50%);
            }

        }
    }
    .rt-customcheckbox input:checked ~ .rt-checkmark{
        background: rgba(0, 0, 0, 0.7);
        &::after{
            color: $electriclime;
        }
    }
    .rt-sessionslist{
        .rt-themecontent{
            h4{
                color: $nero;
                font-size: 1.25rem;
                margin-bottom: 12px;
            }
            p{
                margin: 0;
                font-size: 16px;
                color: $suvagrey;
                @include roboto-regular;
            }
        }
    }
}