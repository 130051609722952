@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-videocard{
    padding: 16px;
    background: $white;
    margin-bottom: 16px; 
    @include bd-radius(16px);

    .rt-themecardbox{
        align-items: flex-start;

        h4{
            font-size: 1.5rem;
            @include norwester;
            margin-bottom: 10px;

            a{
                display: block;
                color: inherit;
            }
        }

        p{
            margin: 0;
            font-size: 16px;
            color: $suvagrey;
            @include roboto-regular;
        }
    }
}
.rt-videoimg{
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    margin: 0 16px 0 0;
    @include bd-radius(16px);

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    .rt-videoicon{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        background: $nerolight;
        justify-content: center;

        i{
            color: $white;
            font-size: 30px;
        }
    }
}