@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-course-content{
    h3{
        margin-bottom: 6px;
        font-size: 1.125rem;
    }
    .rt-time{
        color: $nero;
        display: block;
        font-size: 14px;
        @include roboto-medium;

        i{
            color: $PinkSwan;
            margin-right: 6px;
        }
    }
}