@import "../../../assets/css/mixin.scss";
@import "../../../assets/css/variables.scss";

.rt-createpasswordholder{
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .rt-formbox{
        /* width: 545px; */
        padding-top: 0;
        .rt-formtheme{
            fieldset{height: auto;}
            .form-group{
                margin: 0 0 16px;
                &:nth-child(2){margin-bottom: 50px;}
            }
        }
    }
    .rt-logobox{margin: 0 0 140px;}
    .rt-fieldholder{
        height: 66px;
        background: $whisper;
        @include bd-radius(12px);
    }
}