@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-organize-training{
    display: flex;
}
.rt-calendararea{
    margin-right: 16px;
    width: calc(100% - 438px);
    .rt-schedule{
        background: $whisperfive;
        .rt-title{
            padding: 20px 40px;
            border-color: rgba(27,27,27,0.1);
            h2{
                color: $nero;
                font-size: 1.75rem;
            }
        }
        .fc-header-toolbar{
            .fc-toolbar-title{
                color: $nero;
                font-size: 1.25rem;
            }
            .fc-prev-button, 
            .fc-next-button{
                color: $nero;
            }
        }
        .fc .fc-button-primary:not(:disabled):active:focus,
        .fc .fc-button-primary:not(:disabled).fc-button-active:focus{
            box-shadow: none;
        }
        .fc-daygrid-day .fc-daygrid-day-top{
            width: 40px;
            height: 40px;
            line-height: 40px;
            border: 2px solid $nero;
        }
        .fc-theme-standard{
            height: 430px;

            th .fc-col-header-cell-cushion{
                color: $nero;
                font-size: 14px;
                @include norwester;
            }
            td a{
                color: $nero;
                font-size: 14px;
                @include roboto-regular;
            }
        } 
    }
}
.rt-sessionsidebar{
    width: 438px;
    height: 810px;
    background: $whisperfive;
    .rt-title{
        padding: 0 30px 16px;
        flex-direction: column;
        align-items: flex-start;
        h2{
            color: $nero;
            margin: 0 0 5px;
        }
        span{
            color: $nobel;
            display: block;
            font-size: 16px;
            @include norwester;
        }
        .rt-backbtn{
            margin-right: 10px;
            background: transparent;
        }
    }
    .rt-sessionslistholder{
        height: calc(100% - 68px);
    } 
}
.rt-selectcategory{
    .rt-category{
        height: 180px;
        margin-bottom: 12px;
    }
}
.rt-selectvideocard{
    position: relative;
}
.rt-submitbtn{
    bottom: 0;
    right: 30px;
    width: 50px;
    height: 50px;
    font-size: 22px;
    line-height: 50px;
    background: $nero;
    position: absolute;
    color: $electriclime;
    @include bd-radius(50%);
}
/* =============================================
            Responsive
============================================= */

@media (max-width: 1300px){
    .rt-calendararea{
        width: calc(100% - 380px);
    }
    .rt-sessionsidebar{
        width: 365px;
    }
}
@media (max-width: 991px){
    .rt-organize-training{
        flex-direction: column;
    }
    .rt-calendararea{
        width: 100%;
        margin-bottom: 20px;
    }
    .rt-sessionsidebar{
        width: 100%;
        padding: 20px 0;
    }
    .rt-sessionsidebar .rt-sessionslistholder{
        padding: 0 15px;
    }
}
@media (max-width: 640px){
    .rt-addsessionlist .rt-themecardbox .rt-themecontent ol li{
        font-size: 16px;
    }
    .rt-addsessionlist .rt-themecardbox .rt-deletbtn{
        font-size: 16px;
    }
    .rt-closesidebar .rt-header {
        width: 100%;
    }
}
@media (max-width: 575px){
    .rt-selectvideocard .rt-videoimg{
        align-self: flex-start;
        align-items: flex-start;
    }
    .rt-submitbtn{
        right: 60px;
        bottom: 50px;
        position: fixed;
    }
    .rt-calendararea .rt-schedule .fc-theme-standard {
        height: 360px;
    }
    .rt-calendararea .rt-schedule .fc-daygrid-day .fc-daygrid-day-top{
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .rt-calendararea .rt-schedule .rt-title{
        padding: 15px 20px;
    }
    .rt-schedule .fc .fc-toolbar.fc-header-toolbar{
        margin: 10px 0;
    }
    .rt-selectvideocard .rt-videoimg{
        margin: 0 15px 0 0;
    }
}
@media (max-width: 479px){
    .rt-sessionslist .rt-themecardbox{
        flex-direction: column;
    }
    .rt-selectvideocard .rt-sessionslist .rt-themecontent h4{
        margin-bottom: 5px;
    }
    .rt-sessionslist .rt-themecardbox {
        padding: 5px 0;
    }
    .rt-addsessionlist{
        .rt-sessionslist{
            .rt-themecardbox{
                flex-direction: row;
            }
            .rt-rectangelbox{
                margin: 0 10px 0 0;
            }
        } 
    }
}