/*
	@Author: Renesis Technologies
	@URL: https://renesistech.com/
	This file contains the styling for the actual theme, this is the file you need to edit to change the look of the theme.

/* =============================================
			Theme Reset Style
============================================= */
html{
  // font-size: 62.5% !important;
}
@import "assets/css/variables";
@import "assets/css/mixin";
* {
	margin: 0px;
	padding: 0px;
}
*:after,
*:before{ @include transition(all,0.2s,ease-out); }
body {
	color: $nero;
  font-size: 16px;
  background:$white;
  line-height: 1.2;
  @include norwester;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      color: $nero;
      line-height: 1.2;
      font-style: normal;
      word-break: break-word;
      text-transform: uppercase;
      font-family: 'Norwester', sans-serif;
  }
}
h1 { font-size: 3.5rem;}
h2 { font-size: 3.75rem;}
h3 { font-size: 2.25rem;}
h4 { font-size: 0.25rem;}
h5 { font-size: 2rem;}
h6 { font-size: 1.5rem;}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { color: $nero;}
p a{ @include transition(all,0.2s,ease-out); }
p a,
p a:hover { text-decoration:underline; }
p {
	margin: 0 0 20px;
	line-height: 1.3;
	letter-spacing: normal;
  word-break: break-word;
}
a {
  color: $nero;
	text-decoration: none;
  @include transition(all,0.2s,ease-out);
  &:hover,
  &:focus,
  &:active {
      color: $nero;
      outline: none;
      text-decoration: none;
  }
}
ul { 
  margin: 0;
  padding: 0; 
  list-style: none;
  
  li{
    list-style-type: none;
  }
}
figure {
	margin-bottom: 6px;
	position: relative;
}
img {
	height: auto;
	max-width: 100%;
}
strong { color: #414141; }
em {font-style: normal;}
iframe {
	width: 100%;
	float: left;
	border: none;
}
button { border: none; }
textarea,
select,
.rt-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	color: #666;
	outline: none;
	height: 40px;
	background:#fff;
	font-size: 14px;
	-webkit-box-shadow: none;
			box-shadow: none;
	line-height: 18px;
	padding: 10px 20px;
	border-radius: 4px;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #333;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $nero;
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
}
textarea
/* .form-control */{
	resize: none;
	height: 210px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-control::-webkit-input-placeholder { color: #666; } /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #666; } /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #666; } /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #666; } /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color:#666; } /* Microsoft Edge */
input:focus,
.select select:focus,
.form-control:focus {
	color: #666;
	border-color: #666;
	-webkit-box-shadow:none !important;
			box-shadow:none !important;
}
.form-group {
	width: 100%;
	float: left;
	margin:0 0 20px;
    position: relative;
}
label {
	color: #666;
	display: block;
	font-weight: 400;
	margin-bottom: 10px;
}
form p {
	float: left;
	position: relative;
    width: 100%;

    p span i {
        top: 13px;
        left: 16px;
        color: #474747;
        position: absolute;
    }
}
option { padding: 5px 15px; }
ul ul { margin-left: 20px; }
ol {
	float: none;
	list-style: decimal;
  padding-left: 15px;

    li {
        list-style: decimal;
        width: 100%;
    }
}
ol ol { margin-left: 20px; }
p ins { color: #666; }
dl dd { margin-left: 20px; }
address { font-style: italic; }
/* =============================================
			Theme Table Custom Style
============================================= */
table {
	background-color: transparent;
	max-width: 100%;
}
th { text-align: left; }
table {
	width: 100%;
	margin-bottom: 24px;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 0;
	line-height: 1.3;
	padding-left: 3px;
	text-align: center;
  white-space: nowrap;
	vertical-align: top;
	border-top: 1px solid $whisper;
}
table thead tr th {
	border: 0;
	text-align: center;
	text-transform: capitalize;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td { border-bottom: 0; }
table > tbody + tbody { border-top: 2px solid #dbdbdb; }
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th{ 
  color: $nero;
  @include norwester;
  padding: 25px 30px; 
  background: $Gainsboro;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  color: $nero;
  @include norwester;
  padding: 20px 30px;
  background: $whisper;
}
.table > tbody > tr:first-child > td {
  border-top: 0;
}

/* =============================================
			Theme Custom Select
============================================= */
.rt-select {
	color:#666;
	float:left;
	width: 100%;
	position: relative;
  text-transform:uppercase;
    &:after {
        top: 0;
        z-index: 2;
        right: 15px;
        color: #666;
        display: block;
        content: '\e90f';
        line-height: 25px;
        position: absolute;
        text-align: center;
        font-size: inherit;
        pointer-events: none;
        font-family: 'icomoon';
    }
    select {
        z-index: 1;
        width: 100%;
        position: relative;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        option { color:#666; }
    }
}
/* =============================================
			Custom Checkbox Style
============================================= */
.rt-customcheckbox-area{
  display: flex;
  align-items: center;
}
.rt-customcheckbox{
  width: auto;
  height: auto;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px 0 0;
  position: relative;
  align-self: center;
  align-items: center;
  text-transform: capitalize;
  @include roboto-regular;
  @include transition;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  .rt-checkmark {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    @include bd-radius(5px);
    border: 2px solid $silver;
    background-color: transparent;
    @include transition;

    &:after {
      top: 3px;
      left: 3px;
      color: $nero;
      font-size: 18px;
      content: '\e91e';
      position: absolute;
      @include transition;
      font-family: 'icomoon';
      display: none;
    }
  }
}
.rt-customcheckbox input:checked ~ .rt-checkmark {
  background-color: $electriclime;
  border-color: $electriclime;
}
.rt-customcheckbox input:checked ~ .rt-checkmark:after{
  color: $nero;
  display: block;
}
/* =============================================
			Custom Radiobox Style
============================================= */
.rt-customradio-area{
  display: flex;
  align-items: center;
}
.rt-customradio {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 10px 0 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input{
      opacity: 0;
      cursor: pointer;
      position: absolute;
  }

  .rt-checkmark {
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      border: 2px solid $nero;
      background-color: transparent;

      &:after {
        content: '';
        display: none;
        position: absolute;
        @include transition;
      }
  }
}
.rt-customradio input:checked ~ .rt-checkmark {
    background-color: transparent;
}

.rt-customradio input:checked ~ .rt-checkmark:after {
  top: 3px;
  left: 3px;
  width: 14px;
  color: $nero;
  height: 14px;
  display: block;
  background: $nero;
  border-radius: 50%;
}
/* =============================================
			Theme btn style
============================================= */
.rt-haslayout {
	width:100%;
	float:left;
}
.rt-btn-sm {
	padding: 0 20px;
	line-height: 30px;
}
.rt-btn {
  color: $nero;
  padding: 0 50px;
  position: relative;
  text-align: center;
  @include transition;
  display: inline-block;
  vertical-align: middle;
  @include bd-radius(12px);
  background: $electriclime;
  text-transform: uppercase;
  font:400 18px/66px 'Norwester', Arial, Helvetica, sans-serif;
  &:focus{outline: none;}
  &:hover,
  &:focus{
    color: $nero;
    background: $inchwormtwo;
  }
  i,
  span{
      display: inline-block;
      vertical-align: middle;
  }
}
.rt-btn-lg { width: 100%;}
.rt-border-btn{
  color: $nero;
  padding: 0 40px;
  background: transparent;
  border: 2px solid $nero;
}
.rt-black-btn{
  color: $white;
  padding: 0 20px;
  background: $nero;
  @include bd-radius(16px);
}
.rt-primary-btn{
  color: $nero;
  background: $whisper;
}
/* =============================================
			Theme Form style
============================================= */
.rt-formtheme {
  width:100%;
  float:left;
  fieldset{
    border:0;
    margin:0;
    padding:0;
    width:100%;
    float:left;
    position:relative;
    label{
        font-size: 16px;
        line-height: 19px;
        @include norwester;
    }
    .rt-uploadimage{
      font-size: 18px;
      line-height: 34px;
    }
    .form-control{
      border: 0;
      padding: 0;
      color: $nero;
      height: auto;
      font-size: 16px;
      margin: 20px 0 0;
      font-weight: 500;
      background: none;
      line-height: 19px;
      @include bd-radius(0);
      font-family: 'Roboto',sans-serif;
    }
    textarea{width: 100%;}
    .rt-select{
      margin-top: 15px;
    }
    select.form-control{
      margin: 0;
    }
  }
}
.rt-inputwithicon{
  position:relative;
  i{
    right:0;
    top:30px;
    z-index:2;
    width: 50px;
    height: 50px;
    color: $black;
    cursor: pointer;
    font-size: 22px;
    line-height: 50px;
    position:absolute;
    text-align: center;
  }
  .form-control{
      z-index:1;
      position:relative;
  }
}
/* =============================================
			Upload Image Style
============================================= */
.rt-roundimage{
  position: relative;
}
.rt-uploadimage {
  top: 15px;
  margin: 0;
  right: -6px;
  width: 40px;
  height: 40px;
  color: $white;
  font-size: 18px;
  cursor: pointer;
  line-height: 34px;
  background: $nero;
  position: absolute;
  @include bd-radius(50%);
  border: 3px solid $Mischka;
  .form-control {
      opacity: 0;
      position: absolute;
      visibility: hidden;
  }
  i{
    line-height: inherit;
  }
}
/* =============================================
			theme Image Style
============================================= */
.rt-roundimage{
  margin: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: $nobel; 
  @include bd-radius(50%);

  img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      @include bd-radius(50%);
  }
  span{
      color: $black;
      display: block;
      font-size: 14px;
      line-height: inherit;
      text-transform: uppercase;
  }
  .rt-close{
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      color: $nero;
      font-size: 12px;
      line-height: 20px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      background: $nobel;
  }
}
/* =============================================
			theme icon Style
============================================= */
.rt-roundicon{
  width: 40px;
  height: 40px;
  line-height: 40px;
  position:relative;
  text-align: center;
  background: $whisper;
  display: inline-block;
  @include bd-radius(50%);
  i{
      color: $grey;
      display: block;
      font-size: 18px;
      line-height: inherit;
      text-transform: uppercase;
  }
}
/* =============================================
			Theme cardbox Style
============================================= */
.rt-themecardbox{
  display: flex;
  align-items: center;
  .rt-roundicon,
  .rt-roundimage{
      margin-right: 12px;
      @include transition;
  }
  .rt-themecontent{
      flex: 1;
      display: flex;
      flex-direction: column;
      h4{
          margin: 0;
          color: $nero;
          font-size: 1.5rem;
          @include norwester;
      }
      .rt-description{
        p{
          margin: 0;
          font-size: 16px;
          color: $nerolight;
          @include roboto-regular;
        }
      }
  }
}
/* =============================================
			Theme Pagination Style
============================================= */
.rt-pagination {
  width:100%;
  float: left;
  margin: 30px 0 0;
  font:11px/27px 'Norwester', Arial, Helvetica, sans-serif;
  ul {
    margin:0;
    padding:0;
    float:right;
    list-style:none;
    text-align: center;
    line-height: inherit;
    li {
      float:left;
      line-height: inherit;
      list-style-type:none;
      a,
      span{
          width: 25px;
          height: 25px;
          color: $white;
          display:block;
          text-align:center;
          position:relative;
          /* background: $ruby; */
          line-height: inherit;
          @include bd-radius(50%);
      }
      &:first-child{padding: 0 0 0 10px;}
      &:last-child{padding: 0 0 0 16px;}
    }
  }
  span{
    float: right;
    font-size: 14px;
    line-height: 25px;
    /* color: $mischkatwo; */
  }
}
/* =============================================
			Theme tabs Style
============================================= */
.rt-navtabs{
  width: 100%;
  background: $whisper;

  .nav-item{
      width: 50%;
      text-align: center;
      background: $whisper;
      
      a{
          padding: 13px;
          color: $DarkGray;
          @include norwester;
          font-size: 1.375rem;
          @include transition;
          border-radius: 20px 20px 0 0;
      }

      a.active{
          color: $nero;
          background: $electriclime;
      }
  }
}

/* =============================================
			Theme Styling
============================================= */
.rt-wrapper {
  height: 100%;
  overflow: hidden;
  min-height: 969px;
  padding: 104px 0 0 344px;
  background: url(./assets/images/signup-bg.jpg) no-repeat;
  background-size: cover;
}
.rt-main{
  display: flex;
  padding: 60px;
}
.rt-content{
  width: 100%;
  padding: 0 160px;
}
.rt-pageheading{
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  .rt-title{
    display: flex;
    margin-bottom: 0;
    align-items: center;

    .rt-backbtn{
      color: $nero;
      display: block;
      font-size: 28px;
      margin-right: 16px;
      i{
        display: block;
      }
    }

    h2{
      margin: 0;
      color: $nero;
      font-size: 1.625rem;
    }
  }
}
.rt-flexcontainer {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: $white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.rt-flexcontainercolumn {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
/* =============================================
            Theme Modal Styling
============================================= */
.rt-thememodal{
  .modal-dialog{
    max-width: 670px;
    overflow: hidden;
  }
  .modal-body,
  .modal-content {
    padding: 0;
  }
  .modal-content{
    border: 0;
    background: $white;
    @include bd-radius(20px);
  }
  .rt-modaltitle{
    margin-bottom: 16px;
    h3{
      margin: 0;
      font-size: 1.5rem;
    }
  }
}
.rt-thememodalbox{
  display: flex;
  padding: 58px 0 30px;
  flex-direction: column;
  .rt-btns{
    margin-top: 10px;
    text-align: center;
    .rt-btn{
        line-height: 50px;
        @include bd-radius(10px);
    }
    .rt-border-btn{
        line-height: 46px;
        margin-right: 16px;
        @include bd-radius(10px);
    }
  }
}
.rt-close{
  top: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  z-index: 5;
  right: 20px;
  width: 34px;
  height: 34px;
  color: $nero;
  outline: none;
  font-size: 12px;
  line-height: 34px;
  text-align: center;
  position: absolute;
  background: $whisperfour;
  @include bd-radius(27px);
}
/* =============================================
            Login Styling
============================================= */
.rt-split{
  z-index: 1;
  width: 50%;
  height: 100%;
  position: relative;
  .rt-flexcontainer{
    height: 100%;
    overflow: auto;
    text-align: left;
  }
}
.rt-signupimg{
  margin: 0;
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
  img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-formbox{
  width: 500px;
  margin: 0 auto;
  overflow: auto;
  padding-top: 80px;
  padding-bottom: 20px;
  @include scrollbarnone;
  .rt-formtheme{
    .form-group{
      h2{
        color: $nero;
        span{
          display: block;
          font-size: 22px;
          margin: 0 0 5px;
          color: $suvagrey;
          line-height: 24px;
        }
      }
      h3{
        color: $nero;
        span{
          display: block;
          font-size: 18px;
          margin-top: 15px;
          line-height: 23px;
          color: $suvagreythree;
          @include roboto-regular;
        }
      }
      h3{color: $nero;}
    }
  }
}
.rt-logobox{
  width: 100%;
  float: left;
}
.rt-signuplogo{
  width: 86px;
  float: left;
  height: 99px;
  a,
  img{display: block;}
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.rt-fieldholder{
  width: 100%;
  float: left;
  height: 60px;
  padding: 10px 20px;
  position: relative;
  background: $whisper;text-transform: uppercase;
  @include bd-radius(12px);
  label{
    top: 50%;
    left: 20px;
    margin: 0;
    z-index: 4;
    float: left;
    color: $verylightgrey;
    position: absolute;
    transform-origin: 0 0;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @include transition(all,0.3s,ease-out);
    &:focus{outline: none;}
  }
  input::-webkit-input-placeholder{
    opacity: 0;
    transition: inherit;
  }

  textarea + label,
  .rt-select + label,
  &:hover label,
  input:hover + label,
  input:not(:placeholder-shown) + label,
  input:focus + label{
    font-size: 12px;
    color: $suvagrey;
    transform: translateY(-22px) scale(1);
  }
}
.rt-btnbgwhisper{
  color: $mariner;
  background: $whisper;
  &:hover,
  &:focus{
    color: $mariner;
    background: $whisperthree;
  }
}
.rt-signuplogintextbox{
  span{
    font-size: 16px;
    color: $suvagrey;
    line-height: 19px;
    @include norwester;
    button{
        border: 0;
        padding: 0;
        color: $nero;
        font-size: 16px;
        background: none;
        line-height: 19px;
        @include norwester;
        @include bd-radius(0);
        display: inline-block;
        vertical-align: baseline;
        border-color: transparent;
    }
  }
  .btn-primary:hover,
  .btn-check:focus+.btn-primary,
  .btn-primary:focus{
    color: $nero;
    background: none;
    box-shadow: none;
    border-color: transparent;
  }
}
.rt-alertmessagetext{
  width: 100%;
  float: left;
  font-size: 14px;
  margin-top: 8px;
  color: $cinnabar;
  line-height: 17px;
  font-style: normal;
  i{margin: 0 10px 0 0;}
}
/* =============================================
            Forgot-Password Styling
============================================= */
/* =============================================
            Create-Password Styling
============================================= */
/* =============================================
            Dashboard Styling
============================================= */
.rt-categores{display: grid;}





.day-highlight  .fc-daygrid-day-top {
  background-color: yellow !important;
}
.day-highlight  .fc-daygrid-day-number {
  color: black !important;
}




@import "assets/css/responsive.scss";