@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-settingsarea{
    display: flex;
    margin: 0 auto;
    margin-top: 18px;
    max-width: 516px;
    
    .rt-btn{
        margin-top: 28px;
    }
}
.rt-navtabs{
    overflow: hidden;
    margin-bottom: 56px;
    @include bd-radius(16px);

    .nav-item{
        a{
            border-radius: 0;
            padding: 23px 13px;
            font-size: 1.25rem;
        }
    }
}
.rt-changepassword{
    .rt-fieldholder{
        height: 66px;
    }
}
.rt-customradiobtn{
    width: 100%;
    height: 66px;
    display: flex;
    cursor: pointer;
    position: relative;
    justify-content: center;
    input{
        opacity: 0;
        cursor: pointer;
        position: absolute;
    }
    span{
        width: 100%;
        display: flex;
        align-items: center;
        @include transition;
        border-radius: 12px;
        background: $whisper;
        justify-content: center;
        border: 2px solid $whisper;

    }
}
.rt-customradiobtn input:checked ~ span{
    @include transition;
    border: 2px solid $nero;
}
