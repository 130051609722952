@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-progressbox{
    margin-bottom: 50px;

    .rt-title{
        margin-bottom: 20px;

        h2{
            font-size: 1.625rem;
        }
    }
}
.rt-progresscontent{
    overflow: hidden;
}
.rt-progresslist{
    overflow-x: scroll;
    @include scrollbarnone;

    .rt-roundicon{
        width: 120px;
        height: 120px;
        color: $white;
        @include norwester;
        line-height: 120px;
        font-size: 2.125rem;
        background: $Gainsboro;

        &.rt-red{
            background: $Wewak;
        }

        &.rt-yellow{
            background: $Salomie;
        }
    }
    .react-multi-carousel-item{
        padding: 0 15px;
    }
}

/* =============================================
            Responsive
============================================= */

@media (max-width: 767px) {
    .rt-progresslist .rt-roundicon{
        width: 90px;
        height: 90px;
        line-height: 90px;
    }
}
@media (max-width: 460px) {
    .rt-progresslist .rt-roundicon{
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
}
