@media (max-width: 1880px) {
    .rt-signuplogo {
        width: 70px;
        height: 80px;
    }
    .rt-formbox {
        padding-top: 50px;
        .rt-formtheme{
            .form-group {
                h2 {
                    span {
                        font-size: 20px;
                        line-height: 23px;
                    }
                }
                h3 {
                    span {
                        font-size: 16px;
                        margin-top: 10px;
                        line-height: 17px;
                    }
                }
            }
        }
        .rt-btn {
            font-size: 16px;
            line-height: 60px;
        }
    }
    .rt-loginholder,
    .rt-createpasswordholder,
    .rt-forgotpasswordholder{
        .rt-fieldholder {height: 60px !important;}
    }
    .rt-btnforgotpassword{
        font-size: 14px !important;
        line-height: 17px !important; 
    }
    .rt-signuplogintextbox{
        span {
            font-size: 14px !important;
            line-height: 17px !important;
        }
    }
    .rt-signinwithtext {font-size: 16px !important;}
    .rt-loginholder {
        .rt-formbox{
            .rt-formtheme {
                .form-group{
                    &:nth-child(5) {margin: 0 0 30px !important;}
                }
            }
        }
    }
    .rt-forgotpasswordholder {
        .rt-formbox {
            .rt-formtheme {
                .form-group{
                    &:nth-child(3) {margin: 0 0 20px !important;}
                }
            }
        }
    }
    .rt-createpasswordholder {
        .rt-logobox {margin: 0 0 100px;}
        .rt-formbox {
            .rt-formtheme {
                .form-group{
                    &:nth-child(2) {margin-bottom: 30px;}
                }
            }
        }
    }
}
@media (max-width: 1666px) {
    .rt-content{
        padding: 0 80px;
    }
}
@media (max-width: 1660px) {
    .rt-wrapper{
        padding: 104px 0 0 100px;
    }
}
@media (max-width: 1440px) {
    html {
        font-size: 85% ;
    }
    .rt-formbox {
        .rt-loginform{
            .form-group:nth-child(2) {margin: 0 0 40px !important;}
        }
    }
    .rt-main{
        padding: 60px 40px;
    }
}
@media (max-width: 1200px) {}
@media (max-width: 1199px) {
    .rt-split {width: 100%;}
    .rt-rightsite{
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.9);
    }
    .rt-loginholder .rt-formbox {
        width: 500px;
        .rt-formtheme {
            .form-group{
                h2 {
                    color: $white;
                    span{color: $white;}
                }
                h3{color: $white;}
            }
        }
    }
    .rt-loginholder .rt-fieldholder{background: $white !important;}
    .rt-btnforgotpassword{color: $white !important;}
    .rt-signuplogintextbox{
        span{
            color: $white;
            a{color: $electriclime;}
        }
    }
    .rt-signinwithtext{
        line-height: 25px !important;
        color: $suvagreytwo !important;
        background: $darkgreen !important;
    }
    .rt-signinwithtextbox{
        &:after{background: $nightrider !important;}
    }
    .rt-createpasswordholder{
        .rt-logobox {margin: 0 0 80px;}
    }
    .rt-registrationmodalbox {padding: 53px 50px 20px !important;}
    .rt-content {
        padding: 0 0px;
    }
    .rt-main{
        padding: 30px;
    }
}
@media (max-width: 991px) {
    .rt-formtheme fieldset .form-control{
        font-size: 15px;
    }
}
@media (max-width: 767px) {
    html {
        font-size: 80%;
    }
}
@media (max-width: 640px) {
    .rt-loginholder{
        .rt-logobox {margin: 0 0 30px !important;}
    }
    .rt-forgotpasswordholder{
        .rt-logobox {margin: 0 0 60px !important;}
    }
    .rt-signuplogo {
        width: 50px;
        height: 60px;
    }
    .rt-formbox {
        width: 100%;
        padding: 15px !important;
        .rt-btn {
            line-height: 45px;
            @include bd-radius(5px);
        }
        .rt-formtheme {
            .form-group{
                h2 {
                    font-size: 36px;
                    line-height: 40px;
                    span{
                        font-size: 16px;
                        margin: 0 0 8px;
                    }
                }
                h3{
                    span{font-size: 14px;}
                }
            }
        }
        .rt-loginform {
            .form-group{
                &:nth-child(2) {margin: 0 0 30px !important;}
            }
        }
    }
    .rt-formtheme {
        fieldset {
            label {
                font-size: 14px;
                line-height: 17px;
            }
            .form-control {
                font-size: 14px;
                margin: 15px 0 0;
            }
        }
    }
    .rt-loginholder,
    .rt-createpasswordholder,
    .rt-forgotpasswordholder{
        .rt-fieldholder {
            height: 50px !important;
            padding: 10px 15px !important;
            @include bd-radius(5px !important);
        }
    }
    .rt-fieldholder{
        label{left: 15px;}
        input:hover + label,
        input:focus + label,
        &:hover label
        input:not(:placeholder-shown) + label{transform: translateY(-18px) scale(1);}
    }
    .rt-forgotpasswordholder {
        .rt-formbox {
            .rt-formtheme {
                .form-group{
                    &:nth-child(2) {margin: 0 0 30px !important;}
                    &:nth-child(3) {margin: 0 0 15px !important;}
                }
            }
        }
    }
    .rt-customradio .rt-checkmark{
        top: 2px;
        width: 20px;
        height: 20px;
    }
    .rt-customradio input:checked ~ .rt-checkmark:after{
        width: 10px;
        height: 10px;
    }
}
@media (max-width: 640px) {
    .rt-thememodal .modal-dialog{
        max-width: 100% !important;
        margin: 1rem !important;
    }
    .rt-wrapper{
        padding: 104px 0 0 0;
    }
}
@media (max-width: 575px) {}
@media (max-width: 479px) {
    .rt-wrapper {
        padding: 94px 0 0 0;
    }
    .rt-main {
        padding: 30px 20px;
    }
}