@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-addsessionlist{
    .rt-sessionslist{
        .rt-rectangelbox{
            width: 50px;
            height: 50px;
            line-height: 20px;
            background: $nero;
            border-color: $nero;
        }
    }
    .rt-themecardbox{
        align-items: flex-start;
        .rt-themecontent{
            padding: 14px 0 0;
            ol{
                list-style: decimal;
                margin: 0;
                li{
                    font-size: 1.25rem;
                    @include norwester;
                    list-style-type: decimal;
                }
            }
        }
        .rt-deletbtn{
            color: $nero;
            margin-top: 14px;
            font-size: 1.25rem;
            background: transparent;
        }
    }
}