@import "../../../assets/css/mixin.scss";
@import "../../../assets/css/variables.scss";

.rt-forgotpasswordholder{
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .rt-logobox{margin: 0 0 220px;}
    .rt-fieldholder{
        height: 66px;
        background: $whisper;
        @include bd-radius(12px);
    }
    .rt-formbox{
        .rt-formtheme{
            .form-group{
                &:nth-child(2){margin: 0 0 40px;}
                &:nth-child(3){margin: 0 0 30px;}
                &:last-child{margin: 280px 0 0;}
            }
        }
    }
}