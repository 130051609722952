@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-category{
    width: 100%;
    height: 160px;
    overflow: hidden;
    position: relative;
    @include bd-radius(20px);
    
    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    .rt-category-content{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        background: rgba(27, 27, 27, 0.6);

        a{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            flex-direction: column;
            justify-content: center;

            &:hover{
                h3{
                    color: $electriclime;
                }
            }
        }

        h3{
            color: $white;
            @include transition;
            font-size: 1.875rem;

            span{
                display: block;
            }
        }
    }
}
.rt-sessioncounter{
    right: 16px;
    bottom: 12px;
    display: block;
    position: absolute;
    font-size: 1.25rem;
    color: $electriclime;
    i{
        padding: 3px;
        font-size: 12px;
        margin-right: 8px;
        @include bd-radius(50%);
        border: 2px solid $electriclime;
    }
}