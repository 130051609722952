@import "../../../assets/css/mixin.scss";
@import "../../../assets/css/variables.scss";

.rt-loginholder{
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .rt-logobox{margin: 0 0 80px;}
    .rt-fieldholder{
        height: 66px;
        background: $whisper;
        @include bd-radius(12px);
    }
    .rt-formbox{
        .rt-formtheme{
            .form-group{
                margin: 0 0 15px;
                &:nth-child(2){margin: 0 0 30px;}
                &:nth-child(5){margin: 0 0 30px;}
                &:nth-child(6),
                &:nth-child(7){margin: 0 0 25px;}
                &:last-child{margin: 100px 0 0;}
            }
        }
    }
}
.rt-btnforgotpassword{
    float: right;
    color: $nero;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    &:hover,
    &:focus{
        color: $nero;
        text-decoration: underline;
    }
}
.rt-signinwithtextbox{
    &:after{
        left: 0;
        top: 50%;
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        background: $whispertwo;
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
.rt-signinwithtext{
    z-index: 1;
    height: 25px;
    padding: 0 24px;
    font-size: 18px;
    line-height: 21px;
    background: $white;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: $verylightgreytwo;
}
.rt-btnsigninfacebook{
    i{
        font-size: 24px;
        margin: 0 20px 0 0;
    }
}
/* =============================================
            Signup Modal Styling
============================================= */
.rt-registrationmodalbox{padding: 53px 100px 20px;}
.rt-registercontent{
    width: 100%;
    float: left;
    text-align: center;
}
.rt-formregistration{
    fieldset{
        h4{
            font-size: 2.5rem;
            margin-bottom: 30px;
            span{
                display: block;
                font-size: 16px;
                font-weight: 400;
                margin-top: 10px;
                line-height: 19px;
                color: $suvagreythree;
                text-transform: initial;
                font-family: 'Roboto', sans-serif;
            }
        }
        .form-group{
            a{
                display: block;
                img{
                    height: 146px;
                    display: block;
                    margin: 0 auto 30px;
                }
                span{
                    width: 160px;
                    height: 50px;
                    color: $nero;
                    display: block;
                    font-size: 20px;
                    line-height: 50px;
                    text-align: center;
                    background: $inchworm;
                    display: inline-block;
                    vertical-align: middle;
                    @include bd-radius(10px);
                }
            }
        }
    }
}