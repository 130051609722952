@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-train-categores{

    .rt-categores{
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 16px;
        margin-bottom: 0;
    }
    
    .rt-category{
        .rt-category-content{
            background: rgba(27,27,27,0.5);
            h3{
                font-size: 1.5rem;
            }
        }
    }
} 
/* =============================================
            Responsive
============================================= */

@media (max-width: 1660px){
    .rt-train-categores .rt-categores{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}



