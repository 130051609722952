@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-coursecard{
    width: 100%;
    overflow: hidden;
    background: $white;
    @include bd-radius(16px);
}
.rt-courseimg{
    margin: 0;
    height: 157px;
    overflow: hidden;
    position: relative;
    @include bd-radius(16px);

    a{
        width: 100%;
        height: 100%;
        display: block;
        @include transition;
        &:hover{
            h3{
                color: $electriclime;
            }
        }
    }
    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
}
.rt-course-title{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 15px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: rgba(27,27,27,0.5);

    h3{
        color: $white;
        line-height: 1.5;
        font-size: 1.2rem;
        text-align: center;
        @include transition;

        span{
            display: block;
        }
    }
}
.rt-course-content{
    padding: 18px 16px;

    p{
        margin: 0;
        color: $zambezi;
        font-size: 16px;
        @include ellipsisline;
        @include roboto-regular;
    }
}

