@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-sidebarmenu{
    top: 0;
    left: 0;
    z-index: 11;
    width: 344px;
    height: 100vh;
    position: fixed;
    background: $nero;
    @include transition($what: all, $time: 500ms, $how: ease-in-out);
}
.rt-logoarea{
    display: flex;
    padding: 25px 50px;
    border-bottom: 1px solid $BlackBean;

    .rt-togglemenubtn{
        top: 20px;
        right: 20px;
        display: none;
        color: $white;
        position: absolute;
        background: transparent;
    }

    .rt-logo{
        width: 158px;
        height: 54px;
        overflow: hidden;
        @include transition($what: all, $time: 500ms, $how: ease-in-out);

        a{
            display: block;
        }
        img{
            width: 100%;
            display: block;
            @include transition($what: all, $time: 500ms, $how: ease-in-out);
        }
        .rt-logomobile{
            width: 50px;
            display: none;
            @include transition($what: all, $time: 500ms, $how: ease-in-out);
        }
    }
}
.at-navigation{
    width: 100%;
    padding: 25px 20px;
    overflow-y: scroll;
    @include scrollbarnone;
    height: calc(100% - 193px);
}
.rt-navlist{
    width: 100%;
    list-style: none;
    li{
        display: block;
        list-style-type: none;
        a{
            display: block;
            font-size: 18px;
            overflow: hidden;
            padding: 15px 30px;
            position: relative;
            color: $whisperfour;
            white-space: nowrap;
            border-radius: 10px;
            @include transition;
            text-transform: uppercase;
            i{
                display: inline-block;
            }
            span{
                top: 0;
                right: -16px;
                position: relative;
                @include transition;

            }

            &:hover{
                color: $nero;
                background: $electriclime;
            }
        }
    }
}
.rt-logoutarea{
    width: 100%;

    .rt-logoutbtn{
        width: 100%;
        padding: 25px;
        outline: none;
        display: flex;
        font-size: 2rem;
        align-items: center;
        justify-content: center;
        background: $electriclime;

        span{
            @include transition($what: all, $time: 500ms, $how: ease-in-out);
        }
        
        i{
            margin-left: 12px;
            font-size: 2.5rem;
            display: inline-block;
        }
    }
}

@media (max-width: 1660px) {
    .rt-sidebarmenu{
        width: 100px;
    }

    .rt-logoarea{
        padding: 25px 0;

        .rt-logo{
            width: 50px;
            margin: 0 auto;

            .rt-logomobile{
                display: block;
            }

            .rt-logoweb{
                display: none;
                @include transition;
            }
        } 
    }
    .rt-navlist li a{
        padding: 15px 22px;
        span{
            right: -116px;
        }
    }
    .rt-logoutarea .rt-logoutbtn{
        padding: 25px 0;

        span{
            display: none;
        }

        i{
            margin: 0;
        }
    }
    .rt-closesidebar{
        .rt-sidebarmenu{
            width: 344px;
        }

        .rt-logoarea{
            padding: 25px 50px;

            .rt-logo{
                margin: 0;                
                width: 158px;

                .rt-logomobile{
                    display: none;
                }

                .rt-logoweb{
                    display: block;
                    @include transition;
                }
            } 
        }
        .rt-navlist li a{
            padding: 15px 30px;
            span{
                right: -16px;
            }
        }
        .rt-logoutarea .rt-logoutbtn{
            padding: 25px;

            span{
                display: block;
            }

            i{
                margin-left: 12px;
            }
        }
    }
    .at-navigation{
        height: calc(100% - 183px);
    }
}
@media (max-width: 991px) {
    .rt-closesidebar{
        .rt-togglemenubtn{
            display: block;
        }
    }
    .rt-closesidebar .rt-header {
        width: calc(100% - 100px);
    }
}
@media (max-width: 640px) {
    .rt-sidebarmenu{
        width: 344px;
        left: -345px;
    }

    .rt-logoarea{
        padding: 25px 50px;

        .rt-togglemenubtn{
            display: block;
        }

        .rt-logo{
            margin: 0;                
            width: 158px;

            .rt-logomobile{
                display: none;
            }

            .rt-logoweb{
                display: block;
                @include transition;
            }
        } 
    }
    .rt-navlist li a{
        padding: 15px 30px;
        span{
            right: -16px;
        }
    }
    .rt-logoutarea .rt-logoutbtn{
        padding: 25px;

        span{
            display: block;
        }

        i{
            margin-left: 12px;
        }
    }
    .rt-closesidebar{
        .rt-sidebarmenu{
            left: 0;
        }
    }
}
@media (max-width: 575px) {}
@media (max-width: 479px) {
    .rt-closesidebar .rt-sidebarmenu{
        width: 320px;
    }
}