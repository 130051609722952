@font-face {
    font-family: 'Norwester';
    src: url('../fonts/norwester/Norwester-Regular.eot');
    src: url('../fonts/norwester/Norwester-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/norwester/Norwester-Regular.woff2') format('woff2'),
        url('../fonts/norwester/Norwester-Regular.woff') format('woff'),
        url('../fonts/norwester/Norwester-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@mixin norwester {font-family: 'Norwester', sans-serif;}
@mixin roboto-light {
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}
@mixin roboto-regular {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
@mixin roboto-medium {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
@mixin roboto-bold {
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
@mixin roboto-black {
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}
@mixin bd-radius($value) {
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}
@mixin transition($what: all, $time: 300ms, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}
@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
        @include bd-radius(10px);
        background-color: $whisper;
    }

    &::-webkit-scrollbar-thumb {
        background: $electriclime;
        @include bd-radius(10px);
    }
}
@mixin scrollbarnone {
    &::-webkit-scrollbar {display: none;}
}
@mixin after-before() {
    top: 0;
    left: 0;
    content: '';
    position: absolute;
}
@mixin clearcontent {
    clear: both;
    content: '';
    display: block;
}
@mixin ellipsis($width) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: $width;
}
@mixin ellipsisline {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
@mixin cardlayout{
    background: $whisperfive;
    @include bd-radius(16px);
}