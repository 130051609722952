@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-challengesbox{
    margin-bottom: 60px;
    .rt-title{
        margin-bottom: 24px;
        h2{
            font-size: 1.25rem;
        }
    }
}
.rt-challengeslist{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li{
        text-align: center;
        padding: 0 30px 10px 0;
        list-style-type: none;
        a{
            display: block;
        }
        button{
            background: transparent;
        }
        .rt-roundimage{
            width: 100px;
            height: 100px;
            line-height: 100px;
            margin: 0 auto 12px;
        }
        h3{
            margin: 0;
            color: $nero;
            font-size: 16px;
            @include norwester;
        }
    }
}
/* =============================================
            Won Challenge Modal Styling
============================================= */
.rt-wonchallengemodal{
    .modal-dialog{
        max-width: 600px;
    }
}
.rt-wonchallengecontent{
    padding: 0 20px;
    text-align: center;
    h3{
        color: $nero;
        @include norwester;
        font-size: 1.875rem;
    }
    .rt-modaltitle{
        margin: 0;
        h3{
            margin-bottom: 10px;
        }
        .rt-description{
            p{
                margin: 0;
                font-size: 16px;
                color: $suvagreythree;
                @include roboto-regular;
            }
        }
    }
    .rt-badgebox{
        padding: 40px 0;
        .rt-roundimage{
            width: 100px;
            height: 100px;
            line-height: 100px;
            margin: 0 auto 12px;
        }
        h4{
            color: $nero; 
            font-size: 16px;
            @include norwester;
        }
    }
}
/*========================================
            Responsive 
==========================================*/
@media (max-width: 767px) {
    .rt-challengesbox {
        margin-bottom: 40px;
    }
    .rt-challengeslist{
        li{
            padding-right: 20px;
            .rt-roundimage {
                width: 70px;
                height: 70px;
                line-height: 70px;
            }
            h3{
                font-size: 14px;
            }
        } 
    } 
}